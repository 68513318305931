






















export default {
  name: 'ContactPopup',
  data() {
    return {
      show: false,
      popupActive: false,
      email: 'admin@checkleaked.cc',
      linkedinLink: 'https://www.linkedin.com/company/checkleakedcc/',
      twitterLink: 'https://twitter.com/checkleaked',
      discordLink: 'https://checkleaked.cc/discord',
      facebookLink: 'https://www.facebook.com/LeakCheckcc-104957491305577',
    };
  },
  components: {
    CardContact: () => import('@/components/Contact/CardContact.vue'),
  },
  methods: {
    t(text) {
      return this.$vuetify.lang.t(`$vuetify.Contact.${text}`);
    },
  },
};
